<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";

export default {
  locales: {
    en: {},
    pt: {},
    es: {},
  },
  components: { Layout },
  data() {
    return {
      loading: false,
      success: false,

      license: null,
      perContract: "R$ -.--",
      value: "0.00",
      total: "R$ -.--",

      balance: "R$ -.--",
      contracts: "1",

      alert: {
        type: "",
        message: "",
        params: [],
      },
    };
  },
  validations: {
    contracts: { required },
  },
  methods: {
    getLicense() {
      api.get("licenses").then((response) => {
        if (response.data.status == "success") {
          this.license = response.data.license;
        }
      });
    },
    getValue() {
      api.get("licenses/value").then((response) => {
        if (response.data.status == "success") {
          this.perContract = this.$options.filters.currency(
            response.data.value
          );
          this.value = response.data.value;
          this.total = this.$options.filters.currency(response.data.value);
        }
      });
    },
    getBalance(wallet) {
      this.balance = "R$ -.--";
      api.get("wallet/balance/" + wallet).then((response) => {
        if (response.data.status == "success") {
          this.balance = this.$options.filters.currency(response.data.balance);
        }
      });
    },
    buyLicense() {
      this.loading = true;
      this.$v.$touch();

      if (this.contracts) {
        api
          .post("licenses", {
            contracts: this.contracts,
          })
          .catch((error) => {
            if (error) {
              this.loading = false;
            }
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.contracts = "1";
              this.$v.$reset();

              this.$router.push('/licenses');
            } else {
              this.alert.type = "alert-danger";
              this.alert.message = response.data.message;

              this.$v.$reset();
            }

            this.loading = false;
          });
      }
    },
  },
  mounted() {
    this.getLicense();
    this.getValue();
    this.getBalance("network");
  },
  watch: {
    contracts: function (v) {
      this.total = this.$options.filters.currency(v * this.value);
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">Comprar Licença</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-7">
        <div class="card">
          <div class="card-body p-4">
            <b-form class="p-3" @submit.prevent="buyLicense">
              <div v-if="alert.message" :class="'alert ' + alert.type">
                {{ t(alert.message) }}
              </div>
              <b-form-group
                id="balance"
                :label="t('Saldo disponível')"
                label-for="balance"
              >
                <b-form-input
                  id="balance"
                  class="bg-light"
                  v-model="balance"
                  type="text"
                  disabled
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="perContract"
                :label="t('Valor por contrato')"
                label-for="perContract"
              >
                <b-form-input
                  id="perContract"
                  class="bg-light"
                  v-model="perContract"
                  type="text"
                  disabled
                ></b-form-input>
              </b-form-group>
              <b-form-group
                v-if="license && license.status && license.status == 'active'"
                id="untilDay"
                :label="t('Cobrança até o dia')"
                label-for="untilDay"
              >
                <b-form-input
                  id="untilDay"
                  class="bg-light"
                  v-model="license.date.end"
                  type="text"
                  disabled
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="contracts"
                :label="t('Número de contratos')"
                label-for="contracts"
              >
                <b-form-input
                  id="contracts"
                  v-model="contracts"
                  type="number"
                  :class="{ 'is-invalid': $v.contracts.$error }"
                ></b-form-input>
                <div v-if="$v.contracts.$error" class="invalid-feedback">
                  <span v-if="!$v.contracts.required">{{
                    t("Amount is required.")
                  }}</span>
                </div>
              </b-form-group>
              <b-form-group
                id="total"
                :label="t('Valor a pagar')"
                label-for="total"
              >
                <b-form-input
                  id="total"
                  class="bg-light"
                  v-model="total"
                  type="text"
                  disabled
                ></b-form-input>
              </b-form-group>
              <div class="mt-4">
                <b-button
                  :disabled="loading == true || this.contracts <= '0'"
                  type="submit"
                  variant="default"
                >
                  Comprar
                  <b-spinner
                    v-if="loading"
                    small
                    class="ml-2 align-middle"
                    variant="white"
                    role="status"
                  ></b-spinner>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
